<template>
  <div class="flex-sc flex-col lg:flex-row mt-44 lg:mt-0 w-full lg:h-screen">
    <div class="flex flex-col items-start justify-between lg:h-4/5 xl:w-2/5 lg:py-20">
      <div class="flex items-start flex-col">
        <h2 class="inter text-2xl xl:text-5xl font-bold">{{$t('home.skills')}}</h2>
        <p class="text-start mt-4 tracking-widest text-sm">{{$t('home.under-skills')}}</p>
      </div>
      <div>
        <h3 class="font-bold text-xl lg:text-2xl xl:text-3xl tracking-widest text-start uppercase hidden lg:inline-block">We can do much more, <router-link to="/contact" class="underline">contact us.</router-link></h3>
      </div>
    </div>
    <div class="flex-cb flex-col w-full xl:w-4/5 h-full lg:h-4/5 inter py-16">
      <div class="skills-box">
        <div class="skills w-64" data-aos="zoom-in-right">
          <img src="@/assets/phone.png" alt="Phone icon"/>
          <p class="skills-header text-zayn">{{$t('home.skill.mobile-header')}}</p>
          <p class="skills-description">{{$t('home.skill.mobile-description')}}</p>
        </div>
        <div class="skills my-16 lg:my-0" data-aos="zoom-in-left">
          <img src="@/assets/laptop.png" alt="Laptop icon"/>
          <p class="skills-header">{{$t('home.skill.desktop-header')}}</p>
          <p class="skills-description">{{$t('home.skill.desktop-description')}}</p>
        </div>
      </div>
      <div class="skills-box">
        <div class="skills" data-aos="zoom-in-right">
          <img src="@/assets/web.png" alt="Web icon"/>
          <p class="skills-header">{{$t('home.skill.web-header')}}</p>
          <p class="skills-description">{{$t('home.skill.web-description')}}</p>
        </div>
        <div class="skills my-12 lg:my-0" data-aos="zoom-in-left">
          <img src="@/assets/code.png" alt="Code icon"/>
          <p class="skills-header">{{$t('home.skill.more-header')}}</p>
          <p class="skills-description">{{$t('home.skill.more-description')}}</p>
        </div>
      </div>
      <div class="skills-box">
        <div class="skills" data-aos="zoom-in-right">
          <img src="@/assets/touch.png" alt="Hand icon"/>
          <p class="skills-header">{{$t('home.skill.ux-header')}}</p>
          <p class="skills-description">{{$t('home.skill.ux-description')}}</p>
        </div>
        <div class="flex items-center lg:items-center justify-between flex-col w-64 my-12 lg:my-0" data-aos="zoom-in-left">
          <img src="@/assets/pen.png" alt="Pen icon"/>
          <p class="skills-header">{{$t('home.skill.design-header')}}</p>
          <p class="skills-description">{{$t('home.skill.design-description')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>