export default {
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta strona używa ciasteczek aby świadczyć usługi na najwyższym poziomie. Kontynuując korzystanie ze strony, zgadzasz się na ich użycie."])},
  "cookies-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj"])},
  "cookies-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka Prywatności"])},
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Nas"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "home": {
    "header-text-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative"])},
    "header-text-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "under-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wspieranie przyszłości poprzez innowacje i zaawansowane rozwiązania"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])},
    "about-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy firmą zajmującą się tworzeniem oprogramowania z zespołem doświadczonych programistów specjalizujących się w różnych technologiach i językach programowania. Oferujemy szeroką gamę usług, od projektowania i tworzenia aplikacji od zera do modernizacji istniejących rozwiązań."])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co Potrafimy?"])},
    "under-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko, czego potrzebujesz"])},
    "skill": {
      "mobile-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacje mobilne"])},
      "mobile-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzymy oprogramowanie dla systemów iOS i Android."])},
      "desktop-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacje komputerowe"])},
      "desktop-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacje na urządzenia stacjonarne dla wszystkich systemów operacyjnych."])},
      "web-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacje webowe"])},
      "web-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzymy aplikacje webowe wykorzystując Responsive Web Design i kompatybilność dla każdego systemu i urządzenia."])},
      "more-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne rozwiązania"])},
      "more-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferujemy również konsultacje, pomoc w wyborze odpowiedniej technologii i prowadzenie złożonych projektów."])},
      "ux-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI Design"])},
      "ux-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz zespół graficzny zapewni, że dostarczony produkt jest przyjazny dla użytkownika i estetyczny!"])},
      "design-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "design-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz zespół profesjonalnych projektantów graficznych może stworzyć wszystko dla Ciebie, od logo po projekty wizualizacji 3D!"])}
    }
  },
  "footer": {
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay In Touch With Us"])},
    "left-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz cel"])},
    "right-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naszym celem jest zapewnienie najwyższej jakości usług, dlatego ciągle ulepszamy nasze umiejętności i śledzimy najnowsze trendy technologiczne. Jesteśmy otwarci na indywidualne potrzeby i oczekiwania naszych klientów, dlatego oferujemy elastyczne i dostosowane do potrzeb rozwiązania!"])},
    "circle": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])},
      "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prace"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
    }
  },
  "about": {
    "header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming"])},
    "header-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovations"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartości"])},
    "value-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz zespół ekspertów przesuwa granice tego, co jest możliwe, dzięki zaawansowanej technologii i innowacyjnemu myśleniu. Nasza pasja do programowania jest widoczna w każdym projekcie, w którym bierzemy udział, co skutkuje wyjątkowymi rozwiązaniami, które przekraczają oczekiwania naszych klientów i pozostawiają ich zaskoczonych."])},
    "plan-header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dążymy Do"])},
    "plan-header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doskonałości"])},
    "under-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz proces dostarczania projektów naszym klientom jest staranny i skupiony na zapewnieniu ich satysfakcji. Oto szczegółowy opis kroków, jakie podejmujemy"])},
    "plans": {
      "plan-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początkowa konsultacja: Rozpoczynamy od spotkania z klientem, aby omówić ich cele i wymagania dotyczące projektu."])},
      "plan-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zebranie wymagań: Na podstawie konsultacji zbieramy szczegółowe wymagania, aby lepiej zrozumieć zakres projektu."])},
      "plan-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektowanie rozwiązania: Następnie nasz zespół ekspertów tworzy szczegółowy projekt rozwiązania, który opisuje, jak projekt zostanie wykonany."])},
      "plan-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwój: Po zatwierdzeniu projektu rozwiązania, nasz zespół programistów rozpoczyna jego kodowanie. Używamy najnowszych technologii i stosujemy najlepsze praktyki branżowe, aby zapewnić wysokiej jakości produkt."])},
      "plan-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testowanie: Po zakończeniu rozwoju nasz zespół QA dokładnie testuje rozwiązanie, aby upewnić się, że spełnia wszystkie wymagania i działa zgodnie z oczekiwaniami."])},
      "plan-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wdrożenie: Po pomyślnych testach rozwiązanie jest wdrożone do środowiska klienta i jest dostępne do użytku."])},
      "plan-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wparcie i konserwacja: Nasz zespół jest zobowiązany do zapewniania ciągłego wsparcia i konserwacji, aby zapewnić płynne działanie wdrożonego rozwiązania. Obejmuje to rozwiązywanie wszelkich problemów, wykonywanie regularnych aktualizacji i ulepszeń, aby utrzymać rozwiązanie w aktualnym stanie, oraz prowadzenie monitorowania i konserwacji proaktywnie, aby zapobiegać potencjalnym problemom przed ich wystąpieniem. Naszym celem jest zapewnienie naszym klientom spokoju i utrzymanie ich rozwiązań w optymalnym funkcjonowaniu."])}
    },
    "map-header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globalny"])},
    "map-header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg"])},
    "map-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz zespół działa na skalę globalną, z możliwością dostarczania wysokiej jakości rozwiązań dla klientów w każdym kraju. Nasze doświadczenie, w połączeniu z wykorzystaniem nowoczesnej technologii, pozwala nam pracować bezproblemowo przez granice i strefy czasowe. Niezależnie od tego, czy jesteś zlokalizowany w Europie, Azji czy gdziekolwiek indziej, jesteśmy zobowiązani do dostarczania tych samych poziomów usług i zaangażowania dla wszystkich naszych klientów, bez względu na ich lokalizację. Dzięki silnemu naciskowi na komunikację i współpracę, jesteśmy w stanie skutecznie zarządzać projektami i budować trwałe relacje z klientami z każdego zakątka świata."])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "cp-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa firmy"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])}
  },
  "portfolio": {
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz live"])},
    "FoodMGT": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"FoodMGT\" to aplikacja dla obiektów gastronomicznych, która umożliwia automatyzację procesów związanych z zarządzaniem lokalem. Dzięki FoodMGT, właściciele i managerowie mogą łatwo i szybko zarządzać swoimi lokalami, tworzyć raporty. Klienci mogą za to realizację płatności bez konieczności używania terminalu płatniczego, zamawiać bezpośrednio do stolika bez potrzeby czekania na personel. FoodMGT to narzędzie, które pomoże w optymalizacji działania obiektów gastronomicznych i zwiększeniu efektywności ich działania."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyzacja procesów gastronomicznych, Szybkie zarządzanie restauracją"])}
      },
      "item2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System backendowy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System frontendowy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Quasar, Tailwind"])}
      }
    },
    "EmpowerHR": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja internetowa \"HR Manager\" to narzędzie dla firm, które zajmuje się obsługą HR. Aplikacja umożliwia tworzenie i zarządzanie ofertami pracy, inteligentne analizowanie CV kandydatów za pomocą AI, a także automatyczne dopasowywanie kandydatów z listy. Dzięki temu proces rekrutacji jest szybszy i bardziej efektywny, co pozwala firmom na szybkie znalezienie odpowiedniego kandydata na wakat."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie ofertami pracy, inteligentne analizowanie CV, efektywność procesów rekrutacyjnych"])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL,GATE"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System backendowy:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System frontendowy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Vuetify, Tailwind"])}
      }
    },
    "MPA Consulting": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizacja dla biura rachunkowego MPA Consulting, obejmujące przygotowanie internetowego wizytówki. Strona przygotowana została wraz z projektem graficznym."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowoczesny design, rozbudowa narzędzi dla biura rachunkowego"])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System backendowy:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System frontendowy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Tailwind"])}
      }
    },
    "EnduroGoats": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizacja dla EnduroGoats, obejmująca realizację oraz konfigurację strony internetowej oraz stworzenie panelu CMS"])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowoczesny design, wysoko wydajnościowa strona internetowa"])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System backendowy:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System frontendowy:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Tailwind"])}
      }
    }
  }
}