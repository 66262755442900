<template>
  <div class="flex-center flex-col h-full lg:h-screen py-24 lg:py-0 w-full px-4" data-aos="fade-up-left">
    <h1 class="title text-6xl lg:text-9xl manrope tracking-wider font-bold"><span class="text-zayn">"</span>{{ $t('contact.contact') }} <span class="text-zayn">"</span></h1>
    <div class="border-b-2 border-light w-full py-4"></div>
    <div class="flex items-start flex-col lg:flex-row w-full mt-10 inter tracking-widest">
      <div class="flex items-center justify-start flex-col w-1/2 mt-8 hidden lg:flex">
        <div class="flex flex-col items-center justify-start h-full w-full lg:w-4/5">
          <a class="w-full text-2xl xl:text-4xl manrope uppercase font-bold" href="mailto:contact@zayncode.com">contact@zayncode.com</a>
          <div class="flex flex-col items-center justify-start manrope mt-8">
            <p>ZaynCode Sp. z o.o.</p>
            <p class="text-xs my-2">KRS 0000811133</p>
            <p class="text-xs mb-2">NIP 9671430962</p>
            <p class="text-xs">REGON 384739762</p>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start w-full lg:w-1/2">
        <form class="flex flex-col items-start w-full lg:w-4/5" @submit="handleSubmit">
          <label class="text-xs mb-1.5">E-mail</label>
          <input v-model="mail" class="w-full px-4 py-2 mb-4 rounded-2xl text-sm bg-medium text-light" type="email"/>
          <label class="text-xs mb-1.5">{{ $t('contact.cp-name') }}</label>
          <input v-model="name" class="w-full px-4 py-2 mb-4 rounded-2xl text-sm bg-medium text-light" type="name"/>
          <label class="text-xs mb-1.5">{{ $t('contact.title') }}</label>
          <input v-model="subject" class="w-full px-4 py-2 mb-4 rounded-2xl text-sm bg-medium text-light" type="title"/>
          <label class="text-xs mb-1.5">Text</label>
          <textarea v-model="content" class="w-full px-4 py-2 rounded-2xl text-sm bg-medium text-light" type="email"/>
          <div class="flex items-center justify-end w-full mt-4">
            <button class="px-6 py-2 bg-medium text-light text-sm hover:shadow-md hover:bg-light hover:text-medium hover:font-bold hover:shadow-light rounded-2xl"
                    type="submit">
              {{ $t('contact.send') }}
            </button>
          </div>
        </form>
      </div>
      <div class="flex items-center justify-start flex-col w-full lg:w-1/2 mt-8 lg:hidden">
        <div class="flex flex-col items-center justify-start h-full w-full lg:w-4/5">
          <a class="w-full text-2xl lg:text-4xl manrope uppercase font-bold" href="mailto:contact@zayncode.com">contact@zayncode.com</a>
          <div class="flex flex-col items-center justify-start manrope mt-8">
            <p>ZaynCode Sp. z o.o.</p>
            <p class="text-xs my-2">KRS 0000811133</p>
            <p class="text-xs mb-2">NIP 9671430962</p>
            <p class="text-xs">REGON 384739762</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from "axios";

export default {
  setup() {
    const zoomedCircle = ref(0);
    const circle1 = ref(null);
    const circle2 = ref(null);
    const circle3 = ref(null);
    const mail = ref('')
    const name = ref('')
    const subject = ref('')
    const content = ref('')
    const zoomIn = (index) => {
      zoomedCircle.value = index;
      const elements = [circle1.value, circle2.value, circle3.value];
      elements.forEach((element, i) => {
        if (i + 1 === index) {
          element.classList.add('zoomed');
        } else {
          element.classList.remove('zoomed');
        }
      });
    };

    function handleSubmit(e) {
      e.preventDefault();
      const data = {
        "emailRequest": {
          "emailSender": this.mail,
          "name": this.name.replaceAll(' ',''),
          "subject": this.subject,
          "content": this.content,
          "emailRecipient": 'contact@zayncode.com'
        },
        "smtpConfig": {
          "host": process.env.VUE_APP_EMAIL_HOST,
          "port": process.env.VUE_APP_EMAIL_PORT,
          "protocol": process.env.VUE_APP_EMAIL_PROTOCOL,
          "username": process.env.VUE_APP_EMAIL_USERNAME,
          "password": process.env.VUE_APP_EMAIL_PASSWORD,
          "auth": process.env.VUE_APP_EMAIL_AUTH,
          "starttls": process.env.VUE_APP_EMAIL_STARTTLS
        }
      }

      const self = this;
      axios.post('https://zein-email-api.herokuapp.com/api/email/sendCustomEmail', data)
          .then(function () {
            self.mail = "";
            self.name = "";
            self.subject = "";
            self.content = "";
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    return {
      mail,
      name,
      subject,
      content,
      handleSubmit,
      zoomedCircle,
      circle1,
      circle2,
      circle3,
      zoomIn,
    };
  },
};
</script>

<style>

</style>
