export default {
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use."])},
  "cookies-accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "cookies-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "home": {
    "header-text-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative"])},
    "header-text-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "under-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empowering the future through innovation and advanced solutions"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "about-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a software development company with a team of experienced programmers specializing in various programming technologies and languages. We offer a wide range of services, from designing and creating applications from scratch to modernizing existing solutions."])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What We Can Do?"])},
    "under-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All you need"])},
    "skill": {
      "mobile-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Application"])},
      "mobile-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create software for iOS and Android systems."])},
      "desktop-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop Application"])},
      "desktop-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications for computers for all operating systems."])},
      "web-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Application"])},
      "web-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create web applications using Responsive Web Design and compatibility for every system and device."])},
      "more-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More programming"])},
      "more-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We also offer consultations, help with selecting the appropriate technology, and carry out complex projects."])},
      "ux-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI Design"])},
      "ux-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our graphics team will ensure that the delivered product is user-friendly and visually pleasing!"])},
      "design-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "design-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of professional graphics designers can create everything for you, from logos to 3D visualization projects!"])}
    }
  },
  "footer": {
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay In Touch With Us"])},
    "left-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal"])},
    "right-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal is to provide the highest quality services, so we constantly improve our skills and keep up with the latest technological trends. We are open to the individual needs and expectations of our clients, so we offer flexible and customized solutions."])},
    "circle": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Works"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    }
  },
  "about": {
    "header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming"])},
    "header-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovations"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
    "value-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of experts pushes the boundaries of what's possible with cutting-edge technology and innovative thinking. Our passion for programming shines through in every project we tackle, resulting in outstanding solutions that exceed our clients' expectations and leave them amazed."])},
    "plan-header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Strive for"])},
    "plan-header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfection"])},
    "under-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our process for delivering projects to our clients is thorough and focused on ensuring their satisfaction. Here's a detailed look at the steps we take"])},
    "plans": {
      "plan-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial consultation: We start by meeting with the client to discuss their goals and requirements for the project."])},
      "plan-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements gathering: Based on the consultation, we gather detailed requirements to fully understand the scope of the project."])},
      "plan-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution design: Our team of experts then creates a detailed solution design that outlines how the project will be executed."])},
      "plan-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development: Once the solution design is approved, our developers start coding the solution. We use the latest technology and follow industry best practices to ensure a high-quality product."])},
      "plan-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing: After development is complete, our QA team thoroughly tests the solution to ensure it meets all requirements and is functioning as expected."])},
      "plan-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment: After successful testing, the solution is deployed to the client's environment and made available for use."])},
      "plan-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support and Maintenance: Our team is dedicated to providing ongoing support and maintenance to ensure the smooth operation of the deployed solution. This includes addressing any issues that arise, performing regular updates and upgrades to keep the solution current, and providing proactive monitoring and maintenance to prevent potential problems before they occur. Our goal is to provide peace of mind for our clients and keep their solutions functioning optimally."])}
    },
    "map-header-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "map-header-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach"])},
    "map-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team operates on a global scale, with the ability to deliver high-quality solutions to clients in any country. Our expertise, combined with the use of modern technology, allows us to work seamlessly across borders and time zones. Whether you're located in Europe, Asia, or anywhere in between, we are dedicated to delivering the same level of service and commitment to all our clients, no matter where they are located. With a strong focus on communication and collaboration, we are able to effectively manage projects and build long-lasting relationships with clients from all corners of the world."])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "cp-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "portfolio": {
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check it live"])},
    "FoodMGT": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"FoodMGT\" is a restaurant management app that automates processes related to running a restaurant. With FoodMGT, owners and managers can easily and quickly manage their restaurants and create reports. Customers can make payments without using a payment terminal and order directly to their table without waiting for staff. FoodMGT is a tool that will help optimize restaurant operations and increase their efficiency."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation of gastronomic processes, fast restaurant management"])}
      },
      "item2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend used:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend used:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Quasar, Tailwind"])}
      }
    },
    "EmpowerHR": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The web application \"EmpowerHR\" is a tool for companies that handles HR. The application allows for the creation and management of job offers, intelligent analysis of candidate CVs using AI, and automatic matching of candidates from a list. This makes the recruitment process faster and more efficient, allowing companies to quickly find the right candidate for the vacancy."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of job offers, intelligent analysis of CVs, efficiency of recruitment processes."])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL,GATE"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend used:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend used:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Vuetify, Tailwind"])}
      }
    },
    "MPA Consulting": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizacja dla biura rachunkowego MPA Consulting, obejmujące przygotowanie internetowego wizytówki. Strona przygotowana została wraz z projektem graficznym."])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern design, expansion of tools for an accounting office."])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend used:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend used:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Tailwind"])}
      }
    },
    "EnduroGoats": {
      "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation for EnduroGoats, including the implementation and configuration of the website and the creation of a CMS panel"])},
      "item1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern design, high performance website"])}
      },
      "item2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend used:"])}
      },
      "item3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend used:"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue3, Vuex, Axios, Webpack, Tailwind"])}
      }
    }
  }
}