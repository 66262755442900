import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import en from "./locales/en.json";
import pl from "./locales/pl.json";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import {createI18n} from "vue-i18n";
import {faExclamation, faServer, faWindowRestore ,faAnglesRight,faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
library.add(faLinkedinIn,faTwitter, faServer,  faExclamation, faWindowRestore,faAnglesRight,faAnglesLeft)
function language() {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {

            language = nav.languages[i];
            if (language && language.length) {
                localStorage.setItem("locale",language);
                return language;
            }
        }
    }

    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            localStorage.setItem("locale",language);
            return language;
        }
    }

    return null;
}
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("locale") ?? language(),
    fallbackLocale: "pl_PL",
    messages: {
        "pl_PL": pl,
        "pl": pl,
        "en": en,
        "en_AS": en,
        "en_AU": en,
        "en_BE": en,
        "en_BZ": en,
        "en_BW": en,
        "en_CA": en,
        "en_GU": en,
        "en_HK": en,
        "en_IN": en,
        "en_IE": en,
        "en_IL": en,
        "en_JM": en,
        "en_MT": en,
        "en_MH": en,
        "en_MU": en,
        "en_NA": en,
        "en_NZ": en,
        "en_MP": en,
        "en_PK": en,
        "en_PH": en,
        "en_SG": en,
        "en_ZA": en,
        "en_TT": en,
        "en_UM": en,
        "en_VI": en,
        "en_GB": en,
        "en_US": en,
        "en_ZW": en,
    }
})

createApp(App).use(i18n).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
