<template>
  <div class="flex flex-col items-center justify-center w-7/12 mx-auto py-32">
     <div class="text-center w-full text-3xl font-bold manrope tracking-widest">
       <p>Privacy Policy</p>
     </div>
    <div class="flex items-start justify-center flex-col mt-10 w-full text-sm text-start">
      <p class="font-bold text-lg">I. General provisions</p>
      <ul class="mt-2 tw-pl-4">
        <li>1. The privacy policy defines how personal data of Users necessary for providing services electronically through the website www.zayncode.com and/or www.zayncode.pl (hereinafter: the Service) are collected, processed and stored.</li>
        <li class="mt-2 mb-2">2. The Service collects only the personal data necessary for providing and developing the services offered in it.</li>
        <li>3. Personal data collected through the Service is processed in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/WE (General Data Protection Regulation, hereinafter RODO) and the Act on the Protection of Personal Data of 10 May 2018.</li>
      </ul>
      <p class="mt-4 font-bold text-lg">II. Data administrator</p>
      <p class="text-sm pl-4">The administrator of personal data collected through the Service is ZaynCode Sp. z O.O. with its registered office in Bydgoszcz, KRS: 0000811133, NIP: 9671430962, REGON: 384739762, email address: contact@zayncode.com (hereinafter: the Administrator).</p>
      <p class="mt-4 font-bold text-lg">III. Purpose of collecting personal data</p>
      <div class="text-start pl-4">
        <p>1. Personal data is used for the following purposes:</p>
        <ul class="text-sm pl-4 mb-4 text-sm">
          <li>- implementation of the agreement for services and e-services,</li>
          <li>- communication with the User (contact form),</li>
          <li>- providing social services,</li>
          <li>- marketing, remarketing, affiliations,</li>
          <li>- personalization of the Service for Users,</li>
          <li>- analytical and statistical activities,</li>
          <li>- determination and enforcement of claims or defense against them.</li>
        </ul>
        <p>2.	Providing data is voluntary but necessary to enter into an agreement or use other features of the Service.
        </p>
      </div>
      <p class="mt-4 font-bold text-lg">IV. Types of Personal Data Processed</p>
      <p class="mt-2 mb-4 pl-4 text-sm">The Administrator may process the User's personal data: first and last name, email address, telephone number, VAT number, address.</p>
      <p>V. Period of Processing of Personal Data</p>
      <div class="pl-4">
        <p>Users' personal data will be processed for the period:</p>
        <ul class="pl-4 text-sm">
          <li>- f the basis of processing the data is the performance of the agreement - until the statute of limitations after its performance,</li>
          <li>- if the basis of processing the data is consent - until its revocation, and after revocation of consent until the statute of limitations.</li>
        </ul>
      </div>
      <p class="mt-4 font-bold text-lg">VI. Disclosure of Personal Data</p>
      <div class="pl-4 mb-4 text-sm">
        <p>1.	User's personal data may be disclosed to entities related to the Administrator, its subcontractors, entities cooperating with the Administrator, e.g. e-payment handling companies, courier/postal service companies, legal offices.</p>
        <p>2.	User's personal data will/will not be disclosed outside the European Economic Area (EOG).</p>
      </div>
      <p class="font-bold text-lg">VIII. Cookies</p>
      <div class="pl-4 mb-4 text-sm">
        <p>1.	The Service collects information using cookies - session, permanent, and from third parties.</p>
        <p>2.	Collection of cookies supports the correct provision of services in the Service and serves statistical purposes.</p>
        <p>3.	The User can specify the scope of access of cookies to his device in the browser settings.</p>
      </div>
      <p class="text-start font-bold text-lg">IX. Automated decision-making and profiling</p>
      <div class="pl-4 mb-4 text-sm">
        <p>1.	User data cannot be processed in an automated manner so that any decisions could be made against them.</p>
        <p>2.	User data may be profiled for the purpose of adapting content and personalizing the offer after they have given their consent.</p>
      </div>
      <p class="font-bold text-lg">X. Final provisions</p>
      <div class="pl-4 mb-4 text-sm">
        <p>1.	The Administrator has the right to make changes to the Privacy Policy, provided that the rights of the Users are not limited.</p>
        <p>2.	Information about the introduced changes will appear in the form of a message available in the Service.</p>
        <p>  3.	Matters not regulated in this Privacy Policy are governed by the RODO and Polish law.</p>
      </div>
      <p class="text-xs">Information about the privacy policy and cookies can be found at <a href="https://www.wygranaonline.com/polityka-prywatnosci-wzor-generator/">WygranaOnline</a>.</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>