<template>
  <div class="flex-center flex-col w-full lg:pt-0 mt-0 pb-14">
    <div class="w-full pt-48 border-b-2 border-solid border-light">
      <div class="flex flex-col w-full lg:w-4/6 text-5xl lg:text-[100px] xl:text-[115px] font-bold manrope">
        <h2 class="text-start">Stay In <span class="text-zayn">Touch</span></h2>
        <h2 class="text-end">With Us!</h2>
      </div>
    </div>
    <div class="w-full flex flex-col items-start">
      <div class="w-full flex items-center justify-between mt-2 text-xs lg:text-base tracking-widest">
        <p data-aos="fade-down">{{$t('footer.left-line')}}</p>
        <router-link to="/privacy" data-aos="fade-down">{{$t('footer.right-line')}}</router-link>
      </div>
      <div class="w-full flex-cb flex-col lg:flex-row pt-10 lg:pt-20">
        <div class="w-full lg:w-2/5 text-center lg:text-start inter tracking-wider text-sm lg:text-base font-light">
          <p>{{$t('footer.goal')}}.</p>
        </div>
        <div class="flex items-center justify-between mt-10 lg:mt-0">
          <router-link to="/about" class="manrope contact-circle" data-aos="fade-up"  data-aos-duration="800" @click="scrollToTop">{{$t('footer.circle.about')}}</router-link>
          <router-link to="/portfolio" class="manrope contact-circle mx-10" data-aos="fade-up"  data-aos-duration="1000" @click="scrollToTop">{{$t('footer.circle.works')}}</router-link>
          <router-link to="/contact" class="manrope contact-circle" data-aos="fade-up"  data-aos-duration="1200" @click="scrollToTop">{{$t('footer.circle.contact')}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   setup(){
     function scrollToTop() {
        window.scrollTo(0,0);
     }
     return{
       scrollToTop
     }
   }
}
</script>

<style scoped>

</style>
