<template>
  <div class="flex justify-center items-center"
      ref="container"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @mousemove="handleMouseMove"
      @touchend="handleMouseLeave"
      @touchmove="handleTouchMove"
      @touchstart="handleMouseEnter"
  />
</template>

<script>
import {onMounted, ref, watch} from "vue";

import * as THREE from "three";
import {OBJLoader} from "./utils/OBJLoader";
import Iphone from "./utils/iphone";
import Mac from "./utils/mac";

const phoneObj = new URL("@/assets/iphone.obj", import.meta.url).href;
const macObj = new URL("@/assets/makk.obj", import.meta.url).href;

export default {
  name: "ThreeModel",
  props: {
    screen: {
      type: null,
    },
    mobile:{
      type:Boolean,
      default: false
    },
    lightClr: {
      type: String,
      default: "white",
    },
    phoneClr: {
      type: String,
      default: "white",
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    lookAtAnim:{
      type: Boolean,
      default: false,
    },
    static:{
      type: Boolean,
      default: false,
    },
    horizontal:{
      type: Boolean,
      default: false,
    },
    horizontalScreen:{
      type: null
    },
    obj:{
      type: String,
      default: "phone",
    },
    rotation: {
      type: Object,
      default: () => ({}),
    },
    linearFilter: {
      type: Boolean,
    },
  },
  setup(props) {
    const container = ref(null);
    let camera;
    const materials = [];
    let scene;
    const phones = [];
    let renderer;
    let mouseX = -106;
    let mouseY = 74.5;
    let fov = 0;

    let maxWidth = 0;
    let maxHeight =0;

    function init() {

      if(props.mobile){
        maxWidth = window.innerWidth;
        maxHeight = window.innerHeight/3;
        fov = 25;
      }else{
        fov = 45;
        maxWidth = 500;
        maxHeight = 500;
      }
      const environmentInit = () => {
        camera = new THREE.PerspectiveCamera(fov, maxWidth / maxHeight, 0.1, 10000);
        scene = new THREE.Scene();

        const light = new THREE.DirectionalLight(props.lightClr);
        scene.add(light);
        if(props.obj == 'phone') {
          light.position.set(0, 0, 300);
          camera.position.set(0, 0, 200);
        }else{
            light.position.set(0, 0, 400);
            camera.position.set(0, 0, 10);
          }
      };

      const phoneInit = (screenSrc, home) => {
        let phone;
        if(props.obj == 'phone') {
           phone = new Iphone({
            position: {
              x: 0,
              y: 0,
              z: 0,
              ...home.position,
            },
            rotation: {
              x: props.static ? 0 : -0.02,
              y: props.static ? 0 : -0.3,
              z: props.static?0:props.horizontal? Math.PI/2:-0.1,
              ...home.rotation,
            },
          }, props.static,true);
        }else{
           phone = new Mac({
            position: {
              x: 0,
              y: 0,
              z: 0,
              ...home.position,
            },
            rotation: {
              x: 0,
              y:-0,
              z:0,
              ...home.rotation,
            },
          }, props.static);
        }
        const screenInit = () => {
          const scale = 6;
          const width = scale * 9;
          const height = scale * 19.3;


          let x=1;
          let y=1;
          let radius = 7;

          let shape = new THREE.Shape();
          shape.moveTo( x, y + radius );
          shape.lineTo( x, y + height - radius );
          shape.quadraticCurveTo( x, y + height, x + radius, y + height );
          shape.lineTo( x + width - radius, y + height );
          shape.quadraticCurveTo( x + width, y + height, x + width, y + height - radius );
          shape.lineTo( x + width, y + radius );
          shape.quadraticCurveTo( x + width, y, x + width - radius, y );
          shape.lineTo( x + radius, y );
          shape.quadraticCurveTo( x, y, x, y + radius );

          const geometry = new THREE.ShapeBufferGeometry( shape );

          let texture;

          if (typeof screenSrc === "string") {
            const loader = new THREE.TextureLoader();
            texture = loader.load(screenSrc);
          } else {
            texture = new THREE.VideoTexture(screenSrc);
          }

          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

          const material = new THREE.MeshLambertMaterial({map: texture});
          materials.push(material);
          if (props.linearFilter) {
            material.map.minFilter = THREE.LinearFilter;
          }
          const screen = new THREE.Mesh(geometry, material);

          const recomputeUVs = () => {
            const box = new THREE.Box3().setFromObject(screen);
            const size = new THREE.Vector3();
            box.getSize(size);
            const vec3 = new THREE.Vector3();
            const attPos = screen.geometry.attributes.position;
            const attUv = screen.geometry.attributes.uv;
            for (let i = 0; i < attPos.count; i += 1) {
              vec3.fromBufferAttribute(attPos, i);
              if(props.horizontal) {
                attUv.setXY(
                    i,
                    (vec3.y - box.min.y) / size.y,
                    (vec3.x - box.min.x) / size.x
                );
              }else{
                attUv.setXY(
                    i,
                    (vec3.x- box.min.x) / size.x,
                    (vec3.y - box.min.y) / size.y
                );
              }
            }
          };

          recomputeUVs();
          screen.translateZ(3.6);
          screen.geometry.center();
          phone.add(screen);
        };

        const bodyInit = () => {
          const loader = new OBJLoader();
          let obj = props.obj=='phone'?phoneObj:macObj;
          loader.load(obj, (body) => {
            const bodyGroup = new THREE.Object3D();
            body.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.material = new THREE.MeshLambertMaterial({
                  color: props.phoneClr,
                });
                child.geometry.center();
                const mesh = new THREE.Mesh(child.geometry, child.material);
                const scale = 8.6;
                mesh.rotateX(Math.PI / 2);
                mesh.scale.set(-scale, scale, scale);
                bodyGroup.add(mesh);
              }
            });

            phone.add(bodyGroup);
          });
        };
        if(!props.static) {
          phone.startFloat();
        }
        scene.add(phone);
        screenInit();
        bodyInit();

        return phone;
      };

      renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});


      renderer.setSize(maxWidth, maxHeight);

      environmentInit();

      if (Array.isArray(props.screen)) {
        for (let i = 0; i <= props.screen.length - 1; i += 1) {
          phones.push(
              phoneInit(props.screen[i], {
                position: props.position[i],
                rotation: props.rotation[i],
              })
          );
        }
      } else {
        phones.push(
            phoneInit(props.horizontal?props.horizontalScreen:props.screen, {
              position: props.position,
              rotation: props.rotation,
            })
        );
      }

      container.value.appendChild(renderer.domElement);
    }

    let previousTime = 0;

    function animate(currentTime) {

      currentTime *= 0.001;
      const deltaTime = currentTime - previousTime;
      previousTime = currentTime;

      requestAnimationFrame(animate);

      if (phones.length) {
        phones.forEach((phone) => {
          phone.animation(deltaTime, {
            x: mouseX / 2,
            y: mouseY / 2,
            z: camera.position.z,
          });
        });
      }

      renderer.render(scene, camera);

    }

    function handleMouseEnter() {
      if (phones.length && !props.static && props.lookAtAnim) {
        phones.forEach((phone) => {
          phone.animation = phone.lookAtAnim;
          phone.goingHome = false;
          clearTimeout(phone.homeTimeout);
        });
      }
    }

    function handleMouseLeave() {
      if (phones.length&& !props.static) {
        phones.forEach((phone) => {
          phone.animation = phone.shakeV2Anim;
        });
      }
    }

    function handleMouseMove(event) {
      const rect = container.value.getBoundingClientRect();
      mouseX = event.clientX - rect.left - rect.width / 2;
      mouseY = -(event.clientY - rect.top - rect.height / 2);
    }

    function handleTouchMove(event) {
      event.preventDefault();
      const rect = container.value.getBoundingClientRect();
      mouseX = event.touches[0].clientX - rect.left - rect.width / 2;
      mouseY = -(event.touches[0].clientY - rect.top - rect.height / 2);
    }
    watch(() => props.screen, (newScreen) => {
      materials.forEach(i=>{
        i.needsUpdate = true;
        let newTexture;
        if (typeof newScreen === "string") {
          const loader = new THREE.TextureLoader();
          newTexture = loader.load(newScreen);
        } else {
          newTexture = new THREE.VideoTexture(newScreen);
        }
        newTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        i.map = newTexture;
      })
      console.log(
          "Watch props.selected function called with args:",
          newScreen
      );
    });

    onMounted(() => {
      init();
      animate(0);
    });

    return {
      container,
      handleMouseEnter,
      handleMouseLeave,
      handleMouseMove,
      handleTouchMove,
    };
  },
};
</script>
