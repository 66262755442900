<template>
  <div class="fixed right-0 top-80 flex flex-col items-center justify-between px-4 md:py-8 lg:py-4 w-8 rounded-l-3xl border-b-2 border-l-2 border-t-2 border-solid border-medium bg-dark inter text-xs">
    <div class="flex items-center justify-center relative h-6 w-full" @click="changeLanguage = true">
      <div class="flex items-center md:justify-between lg:justify-center absolute right-5 top-0 h-6 w-20 bg-light rounded-3xl text-dark shadow-md shadow-light" v-if="changeLanguage">
        <button class="p-1 lg:p-2 border-r-[1px] border-dark hover:font-bold" @click="onItemClick('pl')">PL</button>
        <button class="p-1 lg:p-2 hover:font-bold" @click="onItemClick('en')">EN</button>
      </div>
      <p class="cursor-pointer"> {{ currentLanguage }}</p>
    </div>
    <a href="https://twitter.com/zayn_code" class="p-2 lg:mt-4"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
  </div>
</template>

<script>

import {ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "LocaleSwitcher",
  setup(props, context){
    const t = useI18n();
    const currentLanguage = ref('PL')
    const changeLanguage = ref(false)
    const locale = ref({
      label:'English',
      value:'en',
      icon:'EN'
    })
    const chooseLanguage = ref([
        {
         label:'English',
          value:'en',
          icon:'EN'
        },
      {
        label:'Polish',
        value:'pl',
        icon:'PL'
      }
    ]);
    function findLocale(){
      let loc = localStorage.getItem("locale");
      if(loc==null){
        loc = "en";
      }
      const str = loc.includes("-")?loc.split('-')[0] : loc;
      const val = chooseLanguage.value.find(x => x.value == str);
      currentLanguage.value = str.toUpperCase();
      return val;
    }
    function onItemClick(val){
      const newLocale = chooseLanguage.value.find(x => x.value == val);
      this.locale = newLocale;
      localStorage.setItem("locale", this.locale.value);
      t.locale.value = this.locale.value;
      context.emit('update',this.locale.value);
    }
    findLocale();
    return{
      currentLanguage,changeLanguage,chooseLanguage,locale,onItemClick
    }
  },
}
</script>

<style scoped>

</style>
