<template>
  <div v-if="typeData == 'phone'"
       class="flex h-full w-full flex items-center max-lg:flex-col max-lg:w-screen max-lg:min-w-full">
    <div
        class="w-1/2 max-w-[50%] h-full bg-[#e7e7e8] text-black p-40 max-lg:p-8 max-lg:max-h-full max-lg:w-full max-lg:max-w-full max-lg:pb-56">
      <p class="text-3xl lg:text-5xl text-left font-bold tracking-wider border-b-2 text-medium border-dark border-solid inter pb-4 lg:pb-8">
        {{ data.projectName }}</p>
      <p class="text-sm lg:text-lg tracking-wider text-left inter pt-8 pb-4">
        {{ $t('portfolio.' + data.projectName + '.projectDescription') }}</p>
      <div v-if="data.link">
        <a :href="data.link" target="_blank" rel="noopener noreferrer">
          <button class="ml-8 px-4 py-2 bg-light text-dark text-xs uppercase rounded-md inter tracking-widest">{{$t('portfolio.live')}}</button>
        </a>
      </div>
    </div>
    <div
        class="w-1/2 max-w-[50%] h-full pt-20 lg:pt-0 px-4 lg:px-0 bg-[#ffffff] text-[#404040] items-center flex justify-center max-lg:max-h-full max-lg:w-full  max-lg:max-w-full">
      <div class="timeline w-[50%] max-lg:w-[100%]">
        <div v-for="item in data.items" :key="item" class="timeline-container mb-16 max-lg:mb-1">
          <div :class="item.id%2==0 ? 'timeline-icon timeline-icon_s' : 'timeline-icon timeline-icon_f'">
            <font-awesome-icon :icon="item.icon"/>
          </div>
          <div class="timeline-body">
            <p class="timeline-title">{{ $t('portfolio.' + data.projectName + '.item' + item.id + ".title") }}</p>
            <p class="timeline-description">{{ $t('portfolio.' + data.projectName + '.item' + item.id + ".text") }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else class="h-full  w-full h-screen flex items-center">
    <div class="w-1/2 bg-[#e7e7e8]"></div>
    <div class="w-1/2 bg-[#ffffff]"></div>
  </div>
</template>

<script>


import {useI18n} from "vue-i18n";

export default {
  name: "PortfolioScreen",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    typeData: {
      type: String,
      default: "phone"
    }
  },
  setup() {
    const {t} = useI18n() // use as global scope
    function getDesc(data) {
      const path = "portfolio." + data.projectName + ".projectDescription";
      return t(path);
    }

    return {t, getDesc}

  }
}
</script>

<style scoped>
.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 30px;
  color: white;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  display: flex;
  width: 2px;
  height: 88%;
  background: #27293d;
}

.timeline .timeline-container {
  position: relative;

}

.timeline .timeline-container .timeline-icon {
  position: absolute;
  left: -95px;
  top: 4px;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline .timeline-container .timeline-icon img {
  width: 65%;
  height: 65%;
}

.timeline .timeline-container .timeline-body {
  color: black;
  font-family: 'Inter', sans-serif;
  border-radius: 3px;
  text-align: left;
  padding: 20px 20px 15px;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}

.timeline .timeline-container .timeline-body .timeline-title {
  font-size: 22px;
}

.timeline .timeline-container .timeline-body .timeline-description {
  padding-top: 10px;
  font-size: 16px;
}

.timeline .timeline-container .timeline-icon_f {
  background: #e7e6e6;
  color: black;
}

.timeline .timeline-container .timeline-icon_s {
  background: #404040;
  color: white;
}

</style>
