<template>
  <div class="flex flex-col items-start justify-start h-full px-6 lg:px-10" data-aos="fade-up-left">
    <HomeSection/>
    <AboutSection/>
    <SkillsSection/>
    <FooterSection/>
  </div>
</template>

<script>
import HomeSection from "@/components/home/HomeSection";
import AboutSection from "@/components/home/AboutSection";
import SkillsSection from "@/components/home/SkillsSection";
import FooterSection from "@/components/FooterSection";
export default {
  name: 'HomeView',
  components: {
    FooterSection,
    SkillsSection,
    AboutSection,
    HomeSection
  }
}
</script>
