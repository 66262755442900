<template>
  <div :key="locale" class="relative">
    <nav class="fixed top-0 lg:top-[-5%] left-0 right-0 flex items-center justify-between px-6 lg:px-10 w-full z-50">
      <router-link to="/" @click="scrollToTop" class="w-32 md:w-48 xl:w-64 cursor-pointer"><img alt="" src="@/assets/123.png"/></router-link>
      <div class="flex items-center  hidden lg:flex">
        <div class="mr-8 text-xs font-light uppercase inter">
          <router-link to="/about" @click="scrollToTop" class="px-2 py-1 ls">{{$t('nav.about')}}</router-link>
          <router-link to="/portfolio" @click="scrollToTop" class="mx-4 px-2 py-1 ls">{{$t('nav.portfolio')}}</router-link>
        </div>
        <div>
          <img  alt="" src="@/assets/Obraz2.png" class="w-20"/>
        </div>
        <router-link to="/contact" class="ml-8 px-4 py-2 bg-light text-dark text-xs uppercase rounded-md inter tracking-widest">{{$t('nav.contact')}}</router-link>
      </div>
      <div class="w-5 h-4 lg:hidden">
        <div class="flex flex-col items-center justify-between h-full" @click="openBurger = true">
          <span class="w-5 border border-light"></span>
          <span class="w-3 border border-light"></span>
          <span class="w-5 border border-light"></span>
        </div>
        <ul class="flex items-start justify-between flex-col w-1/2 py-6 px-3 pr-6 fixed top-0 right-0 h-1/2 rounded-l-3xl bg-medium" data-aos="fade-left" v-if="openBurger">
          <div class="flex items-end justify-end w-full">
            <button class="flex-center w-8 h-8 font-bold inter rounded-full bg-dark" @click="openBurger = !openBurger">X</button>
          </div>
          <div class="flex items-start justify-start flex-col h-1/2 w-full">
            <router-link to="/about" class="tracking-widest manrope uppercase mb-4" @click="closeBurger()">{{$t('nav.about')}}</router-link>
            <router-link to="/portfolio" class="tracking-widest manrope uppercase mb-4" @click="closeBurger()">{{$t('nav.portfolio')}}</router-link>
            <router-link to="/contact" class="tracking-widest manrope uppercase" @click="closeBurger()">{{$t('nav.contact')}}</router-link>
          </div>
          <div class="w-full flex items-center justify-between">
            <router-link to="/privacy" class="text-[8px]" @click="closeBurger()">{{$t('footer.right-line')}}</router-link>
            <router-link to="/privacy" class="text-[8px]" @click="closeBurger()">Cookies</router-link>
          </div>
        </ul>
      </div>
    </nav>
    <span class="noise"></span>
    <router-view/>
    <div class="fixed bottom-0 flex items-center justify-center w-full px-0 md:px-10 z-40" v-if="!cookiesAccepted">
      <div class="flex flex-col items-center justify-between w-full lg:w-1/2 h-28 px-2 lg:px-4 py-2.5 md:py-6 rounded-t-3xl border-l-2 border-r-2 border-t-2 border-solid border-medium bg-dark inter text-xs">
        <p>{{$t('cookies')}}</p>
        <div class="flex items-center justify-around w-3/5 md:w-2/6 mt-2">
          <button class="px-4 py-2 bg-medium rounded-2xl text-xs" @click="accept">{{$t('cookies-accept')}}</button>
          <router-link to="/privacy" class="px-4 py-2 rounded-2xl text-xs bg-medium">{{$t('cookies-read')}}</router-link>
        </div>
      </div>
    </div>
    <LanguageSwitcher @update="updateLocale"/>
  </div>
</template>
<script>
import {ref} from "vue";
import { useCookies } from "vue3-cookies";
import LanguageSwitcher from "@/components/LanguageSwitcher";
export default{
  components: {
    LanguageSwitcher
  },
  name:'App',
  setup(){
    const locale = ref('');
    const { cookies } = useCookies();
    const cookiesAccepted = ref(false);
    function  accept(){
      this.cookies.set("accept-cookies",'true');
      this.cookiesAccepted=true;
    }
    function updateLocale(newLocale){
      console.log("NEW LOCALE:",newLocale)
      locale.value = newLocale;
    }
    function scrollToTop() {
      window.scrollTo(0,0);
    }
    const openBurger = ref(false);
    function closeBurger(){
      this.openBurger = !this.openBurger
      scrollToTop()
    }
    return {
      cookies,
      cookiesAccepted,
      accept,
      scrollToTop,
      updateLocale,
      locale,
      openBurger,
      closeBurger
    };
  },
  mounted() {
    let c = this.cookies.get("accept-cookies");
    if(c==null){
      this.cookiesAccepted = false;
    }
    this.cookiesAccepted = c!=null&&this.cookies.isKey("accept-cookies") && c.toLowerCase() == 'true';
  },
}
</script>
<style>
html, body {
  margin:0 auto;
  padding:0;
  background-color:#262626;
  color: #ffffff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {

  color: #ffffff;
}

.ls {
  letter-spacing:6px;
}
body::-webkit-scrollbar {
  width: 0.5em;
  height: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
.manrope {
  font-family: 'Manrope', sans-serif;
}
.inter {
  font-family: 'Inter', sans-serif;
}
.noise::before {
  content: "";
  position: fixed;
  left: -10rem;
  z-index: 9999;
  top: -10rem;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  /* background-color: red; */
  background-image: url('@/assets/szum.png');
  opacity: 0.65;
  pointer-events: none;
  animation: noise 1s steps(2) infinite;
}
@-webkit-keyframes noise {
  to {
    transform: translate3d(-7rem, 0, 0);
  }
}

@keyframes noise {
  0% {
    transform: translate3d(0, 9rem, 0);
  }

  10% {
    transform: translate3d(-1rem, -4rem, 0);
  }

  20% {
    transform: translate3d(-8rem, 2rem, 0);
  }

  30% {
    transform: translate3d(9rem, -9rem, 0);
  }

  40% {
    transform: translate3d(-2rem, 7rem, 0);
  }

  50% {
    transform: translate3d(-9rem, -4rem, 0);
  }

  60% {
    transform: translate3d(2rem, 6rem, 0);
  }

  70% {
    transform: translate3d(7rem, -8rem, 0);
  }

  80% {
    transform: translate3d(-9rem, 1rem, 0);
  }

  90% {
    transform: translate3d(6rem, -5rem, 0);
  }

  to {
    transform: translate3d(-7rem, 0, 0);
  }
}

</style>
