<template>
  <div class="flex-center flex-col lg:flex-row w-full h-screen bg-dark lg:mt-20">
    <div class="flex-cs w-1/2 h-full">
      <div class="flex items-center justify-center flex-col mt-10 w-full hidden lg:flex">
        <div class="w-full lg:w-10/12 flex items-center justify-between hidden lg:flex">
          <div class="w-1.5 h-1.5 lg:w-[6px] lg:h-[6px] rounded-full bg-white" data-aos="fade-down" v-for="n in 9" :key="n"></div>
        </div>
        <div class="flex-cb w-full lg:w-9/12 mt-3 hidden lg:flex">
          <div class="lg:w-[10px] lg:h-[10px] rounded-full bg-white" data-aos="fade-down" v-for="n in 8" :key="n"></div>
        </div>
        <div class="flex-cb w-full lg:w-8/12 mt-3">
          <div class="w-2.5 h-2.5 lg:w-[16px] lg:h-[16px] rounded-full bg-white" data-aos="fade-down" v-for="n in 7" :key="n"></div>
        </div>
        <div class="flex-cb w-11/12 lg:w-7/12 mt-3">
          <div class="w-5 h-5 lg:w-[22px] lg:h-[22px] rounded-full bg-white" data-aos="fade-down" v-for="n in 6" :key="n"></div>
        </div>
        <div class="flex-cb w-11/12 lg:w-6/12 mt-3">
          <div class="w-7 h-7 lg:w-[28px] lg:h-[28px] rounded-full bg-white" data-aos="fade-down" v-for="n in 5" :key="n"></div>
        </div>
        <div class="flex-cb w-9/12 lg:w-5/12 mt-3">
          <div class="w-9 h-9 lg:w-[34px] lg:h-[34px] rounded-full bg-white" data-aos="fade-down" v-for="n in 4" :key="n"></div>
        </div>
        <div class="flex-cb w-7/12 lg:w-4/12 mt-4">
          <div class="w-11 h-11 lg:w-[42px] lg:h-[42px] rounded-full bg-white" data-aos="fade-down" v-for="n in 3" :key="n"></div>
        </div>
        <div class="flex-cb w-4/12 lg:w-[120px] xl:w-2/12 mt-4">
          <div class="w-12 h-12 lg:w-[50px] lg:h-[50px] rounded-full bg-white" data-aos="fade-down" v-for="n in 2" :key="n"></div>
        </div>
        <div class="flex-center mt-4">
          <div class="w-16 h-16 lg:w-[62px] lg:h-[62px] rounded-full bg-zayn rgb-circle" data-aos="fade-down"></div>
        </div>
      </div>
    </div>
    <div class="flex items-center lg:items-start justify-center lg:justify-between flex-col h-full lg:h-2/6 w-full lg:w-1/2">
      <h2 class="text-7xl xl:text-[120px] text-start manrope font-bold tracking-wider">{{$t('home.about')}}</h2>
      <p class="w-full lg:w-3/5 mt-4 lg:mt-0 lg:text-start text-sm inter tracking-widest md:text-2xl lg:text-sm font-light">{{$t('home.about-text')}}</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
