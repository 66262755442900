<template>
  <div class="w-full h-screen relative  flex-col items-start justify-start  pt-20 md:flex-row">
    <div class="w-full h-full">
      <button class="drop-shadow-lg fixed bg-white text-black max-lg:w-12 max-lg:h-12 w-24 h-24 top-[50%] left-[1%] rounded-full right-0 z-10" @click="previous">
        <font-awesome-icon icon="fa-solid fa-angles-left" />
      </button>
      <button class="drop-shadow-lg fixed bg-[#e7e7e8] text-black max-lg:w-12 max-lg:h-12 max-lg:left-[87%] w-24 h-24 top-[50%] left-[93%] rounded-full right-0 z-10" @click="next">
        <font-awesome-icon icon="fa-solid fa-angles-right" />
      </button>
      <Transition name="bounce">
        <PortfolioScreen v-if="runAnimation" :data="selectedData"/>
      </Transition>
    </div>
    <Transition name="model">
      <div v-if="runAnimation" :style="{ top: topValue + 'px', left: leftValue + 'px' }"
           class=" absolute left-0 right-0 ml-auto mr-auto max-w-[40%] max-lg:top-[17rem] max-lg:ml-0 max-lg:mr-0">
        <div v-if="isMobile">
          <ThreeModel
              :horizontal-screen="selectedData.himgLink"
              horizontal
              mobile
          />
        </div>
        <div v-else>
          <ThreeModel :screen="selectedData.imgLink"/>

        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import ThreeModel from "@/components/mockup/3DModel.vue";
import PortfolioScreen from "@/components/portfolio/PortfolioScreen.vue";
import {onMounted, onUnmounted, ref} from "vue";

export default {
  name: 'PortfolioView',
  components: {PortfolioScreen, ThreeModel},
  setup() {
    const isMobile = ref(false);
    const screenHeight = window.innerHeight;
    const screenWeight = window.innerWidth;
    const topValue = ref(screenHeight * 0.3);
    const leftValue = ref(screenWeight * 0.25);
    onMounted(() => {
      isMobile.value = isMobileScreen();
      topValue.value = isMobile.value ? getHeight() * 0.45 : getHeight() * 0.3;
      leftValue.value = isMobile.value ? getWidth() * 0.25 : 0;
    })

    function getWidth() {
      var body = document.body;
      var html = document.documentElement;
      var bodyH = Math.max(body.scrollWidth, body.offsetWidth, body.getBoundingClientRect().width, html.clientWidth, html.scrollWidth, html.offsetWidth);
      return bodyH;
    }

    function getHeight() {
      var body = document.body;
      var html = document.documentElement;
      var bodyH = Math.max(body.scrollHeight, body.offsetHeight, body.getBoundingClientRect().height, html.clientHeight, html.scrollHeight, html.offsetHeight);
      return bodyH;
    }

    function isMobileScreen() {
      if (screenWeight <= 850) {
        return true;
      } else {
        return false;
      }
    }

    const runAnimation = ref(true);
    const data = [
      {
        id: 0,
        projectName: "FoodMGT",
        projectDescription: "\"FoodMGT\" to aplikacja dla obiektów gastronomicznych, która umożliwia automatyzację procesów związanych z zarządzaniem lokalem. Dzięki FoodMGT, właściciele i managerowie mogą łatwo i szybko zarządzać swoimi lokalami, tworzyć raporty. Klienci mogą za to realizację płatności bez konieczności używania terminalu płatniczego, zamawiać bezpośrednio do stolika bez potrzeby czekania na personel. FoodMGT to narzędzie, które pomoże w optymalizacji działania obiektów gastronomicznych i zwiększeniu efektywności ich działania.",
        imgLink: require('@/assets/img/food.png'),
        himgLink: require('@/assets/img/food_h.png'),
        items: [
          {
            id: 1,
            title: "Most important:",
            icon: "fa-solid fa-exclamation",
            text: "Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"
          },
          {
            id: 2,
            title: "Backend used:",
            icon: "fa-solid fa-server",
            text: "Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"
          },
          {
            id: 3,
            title: "Frontend used:",
            icon: "fa-solid fa-window-restore",
            text: "Vue3, Vuex, Axios, Webpack, Quasar, Tailwind"
          },
        ],
      },
      {
        id: 1,
        projectName: "EmpowerHR",
        projectDescription: "Aplikacja internetowa \"HR Manager\" to narzędzie dla firm, które zajmuje się obsługą HR. Aplikacja umożliwia tworzenie i zarządzanie ofertami pracy, inteligentne analizowanie CV kandydatów za pomocą AI, a także automatyczne dopasowywanie kandydatów z listy. Dzięki temu proces rekrutacji jest szybszy i bardziej efektywny, co pozwala firmom na szybkie znalezienie odpowiedniego kandydata na wakat.",
        imgLink: require('@/assets/img/hr.png'),
        himgLink: require('@/assets/img/hr_h.png'),
        items: [
          {
            id: 1,
            icon: "fa-solid fa-exclamation",
            title: "Most important:",
            text: "??????????????"
          },
          {
            id: 2,
            icon: "fa-solid fa-server",
            title: "Backend used:",
            text: "Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL,GATE"
          },
          {
            id: 3,
            icon: "fa-solid fa-window-restore",
            title: "Frontend used:",
            text: "Vue3, Vuex, Axios, Webpack, Vuetify, Tailwind"
          },
        ],
      },
      {
        id: 2,
        projectName: "MPA Consulting",
        projectDescription: "Implementation for MPA Consulting accounting office, including the preparation of an online business card. The website was prepared along with a graphic design.",
        link: "https://mpaconsulting.pl/",
        imgLink: require('@/assets/img/mpa3.png'),
        himgLink: require('@/assets/img/mpa_h.png'),
        items: [
          {
            id: 1,
            icon: "fa-solid fa-exclamation",
            title: "Most important:",
            text: "??????????????"
          },
          {
            id: 2,
            icon: "fa-solid fa-server",
            title: "Backend used:",
            text: "Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"
          },
          {
            id: 3,
            icon: "fa-solid fa-window-restore",
            title: "Frontend used:",
            text: "Vue3, Vuex, Axios, Webpack, Tailwind"
          },
        ],
      },
      {
        id: 3,
        projectName: "EnduroGoats",
        projectDescription: "Realizacja dla EnduroGoats, obejmująca realizację oraz konfigurację strony internetowej",
        link: "https://endurogoats.com/",
        imgLink: require('@/assets/img/enduro-m.png'),
        himgLink: require('@/assets/img/enduro.png'),
        items: [
          {
            id: 1,
            icon: "fa-solid fa-exclamation",
            title: "Most important:",
            text: "??????????????"
          },
          {
            id: 2,
            icon: "fa-solid fa-server",
            title: "Backend used:",
            text: "Java, Spring Boot, Spring MVC, Spring Data, Hibernate, MySQL"
          },
          {
            id: 3,
            icon: "fa-solid fa-window-restore",
            title: "Frontend used:",
            text: "Vue3, Vuex, Axios, Webpack, Tailwind"
          },
        ],
      }
    ];
    const dataNow = ref(0);
    let lastTime = ref(0);

    onMounted(() => {
      //document.addEventListener('scroll', scrollPrevent)
      //document.addEventListener('wheel', scrollPrevent)
      // window.addEventListener('scroll', scrollDetect)
      //document.querySelector('#app').addEventListener('wheel', scrollPrevent);
    })
    onUnmounted(() => {
      //document.removeEventListener('scroll', scrollPrevent)
      //document.removeEventListener('wheel', scrollPrevent)
      // window.removeEventListener('scroll',scrollDetect)
      //.querySelector('#app').removeEventListener('wheel', scrollPrevent);
    })


    const mobileScrollTimes = ref(0);

    // eslint-disable-next-line no-unused-vars
    function scrollPrevent(el) {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      let modifier = 0;
      if (isMobileScreen()) {
        if (currentScroll + modifier >= documentHeight) {
          mobileScrollTimes.value++;
          if (mobileScrollTimes.value >= 8) {
            mobileScrollTimes.value = 0;
            scrollDetect(el);

          }
        }
      } else {
        if (currentScroll + modifier >= documentHeight) {
          scrollDetect(el);
        }
      }
    }

    function next() {
      window.scrollTo({top: 0, behavior: 'smooth'});
      const cur = dataNow.value;
      const allData = data.length - 1;
      runAnimation.value = false;
      if (cur >= allData) {
        dataNow.value = 0;
      } else {
        dataNow.value++;
      }
      console.log("PREV", cur)
      setTimeout(() => {
        selectedData.value = data[dataNow.value];
        runAnimation.value = true;
      }, 1000);
    }
    function previous() {
      window.scrollTo({top: 0, behavior: 'smooth'});
      const cur = dataNow.value;
      const allData = data.length - 1;
      runAnimation.value = false;
      if (cur <= 0) {
        dataNow.value = allData;
      } else {
        dataNow.value--;
      }
      console.log("PREV", cur)
      setTimeout(() => {
        selectedData.value = data[dataNow.value];
        runAnimation.value = true;
      }, 1000);
    }

    function scrollDetect(e) {
      e.preventDefault();
      e.stopPropagation();
      if (lastTime.value != 0) {
        if ((Date.now() - lastTime.value) <= 400) {
          return;
        }
        lastTime.value = Date.now();
        if (dataNow.value >= (data.length - 1)) {
          dataNow.value = 0;
        } else {
          dataNow.value++
        }
        runAnimation.value = false;
        window.scrollTo({top: 0, behavior: 'smooth'});
        setTimeout(() => {
          selectedData.value = data[dataNow.value];
          runAnimation.value = true;
        }, 1000)

      } else {
        if (dataNow.value >= (data.length - 1)) {
          dataNow.value = 0;
        } else {
          dataNow.value++
        }
        lastTime.value = Date.now();

        runAnimation.value = false;
        window.scrollTo({top: 0, behavior: 'smooth'});
        setTimeout(() => {
          selectedData.value = data[dataNow.value];
          runAnimation.value = true;
        }, 1000);
      }
    }

    const selectedData = ref(data[dataNow.value]);

    return {data, selectedData, scrollDetect, lastTime, dataNow, runAnimation, isMobile, topValue, leftValue, previous,next}
  }
}
</script>

<style scoped>

@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes wipe-out-right {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}

.timeline {
  display: flex;
  align-items: center;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.timeline-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.timeline-line {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


@keyframes circle-in-center {
  from {
    clip-path: circle(0%);
  }
  to {
    clip-path: circle(125%);
  }
}

@keyframes wipe-out-right {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}

@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.model-enter-active {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-left both;

}

.model-leave-active {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-out-right both
}

.bounce-enter-active {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-left both;
}

.bounce-leave-active {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-out-right both;
}
</style>
