<template>
  <div class="flex flex-col w-full relative">
    <div class="w-full pt-48">
      <div class="flex items-center lg:items-start flex-col w-full lg:w-4/6 text-6xl md:text-[100px] lg:text-[115px] font-bold manrope">
        <h1 class="lg:text-start" data-aos="fade-down" data-aos-duration="1000">Innovative
        <span class="lg:text-end text-zayn lg:ml-20" data-aos="fade-down" data-aos-duration="1000">Technology</span></h1>
      </div>
      <div class="flex items-start mt-40 w-full manrope">
        <h2 class="w-full lg:w-3/6 text-start text-3xl lg:text-5xl xl:text-6xl font-bold leading-snug">{{$t('home.under-header')}}</h2>
      </div>
    </div>
    <div class="absolute bottom-[-25%] lg:bottom-0 right-0">
      <h3 class="text-9xl lg:text-[239px] text-zayn manrope">01</h3>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>